import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject, interval, of, timer } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { ManagerAuthResponse } from '@shared/dto/gateway-public/models';
import { LocalStore } from '@shared/store/local-store';
import { NotificationsService } from 'app/notifications/notifications.service';
import { SettingsState } from '../states/settings.state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService extends BaseObject {
  private readonly VERSION_CHECK_INTERVAL_TIME_SEC = 20;
  private stopListen$ = new Subject<void>();

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private settingsState: SettingsState,
    private store: LocalStore,
    private auth: AuthService,
    private notificationsService: NotificationsService,
  ) {
    super();
  }

  public start(): void {
    this.auth.loggedIn$.pipe(takeUntil(this.destroy$)).subscribe((loggedIn) => {
      this.stopListen$.next();

      if (loggedIn) {
        this.startVersionCheck();
      }
    });

    this.auth.loggedOut$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.stopListen$.next();
    });
  }

  private startVersionCheck(): void {
    this.zone.runOutsideAngular(() => {
      interval(this.VERSION_CHECK_INTERVAL_TIME_SEC * 1000)
        .pipe(
          switchMap(() => this.hasNewVersion()),
          takeUntil(this.stopListen$),
        )
        .subscribe((hasNewVersion) => {
          if (hasNewVersion) {
            this.openUpdateDialog();
            this.stopListen$.next();
          }
        });
    });
  }

  private hasNewVersion(): Observable<boolean> {
    return this.http
      .get<ManagerAuthResponse>(this.settingsState.apiPath + '/auth/check', {
        observe: 'response',
        headers: {
          [LocalStorageConstants.TokenHeader]: localStorage.getItem(LocalStorageConstants.Token),
        },
      })
      .pipe(
        map((res) => {
          const serverVersion = res.headers.get('x-app-version');

          if (!serverVersion) {
            return false;
          }

          const localVersion = this.settingsState.version;

          if (serverVersion !== localVersion) {
            this.settingsState.version = serverVersion;
            return true;
          } else {
            return false;
          }
        }),
        catchError(() => of(false)),
      );
  }

  private openUpdateDialog(): void {
    this.zone.run(() => {
      this.notificationsService.openVersionUpdateDialog().subscribe((result) => {
        switch (result.closeType) {
          case 'YES':
            this.reloadSite();
            break;

          case 'CLOSE':
            if (result.countdownLeftSec) {
              timer(result.countdownLeftSec * 1000)
                .pipe(take(1))
                .subscribe(() => this.reloadSite());
            } else {
              this.reloadSite();
            }
            break;
        }
      });
    });
  }

  private reloadSite(resetLocalStore: boolean = true): void {
    if (resetLocalStore) {
      this.store.clearTablesStore();
    }

    location.reload();
  }
}
