import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  NotificationCondition,
  ON_POST_PUT_NOTIFICATION_URLS_CONDITIONS,
} from '@shared/constants/notification-conditions';
import { CommonErrorData } from '@shared/types/error-responses';
import { NotificationsService } from 'app/notifications/notifications.service';
import { NotificationData, NotificationType } from 'app/notifications/notifications.types';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationsService: NotificationsService) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const { canShow, canShowProgress } = this.canShowNotification(request);
    let progressNotification: NotificationData;

    if (canShowProgress) {
      progressNotification = this.notificationsService.addNotification(
        NotificationType.Progress,
        'Sending data...',
        null,
        { disableTimeOut: true },
      );
    }

    return next.handle(request).pipe(
      tap((event) => {
        if (canShow && event.type === HttpEventType.Response) {
          if (progressNotification) {
            this.notificationsService.removeNotification(progressNotification.id);
          }

          this.notificationsService.addNotification(
            NotificationType.Success,
            'Data sent and saved successfully',
          );
        }
      }),
      catchError((e: HttpErrorResponse) => {
        const error = e.error as CommonErrorData;

        console.error('HttpErrorInterceptor:', error || e);

        if (e) {
          // TODO вурнуть google analytics когда будет нужен (GoogleAnalytics service)
          // this.googleAnalytics.sendError(
          //   error.name + ' interceptor',
          //   JSON.stringify(error.error) || error.message,
          //   error.status,
          // );
          switch (e.status) {
            case 401:
            case 403: {
              // Show current error notification
              if (canShow) {
                if (progressNotification) {
                  this.notificationsService.removeNotification(progressNotification.id);
                }

                this.notificationsService.addNotification(
                  NotificationType.Error,
                  'Access is denied',
                  error?.description,
                );
              }
              break;
            }

            case 404: {
              // Show current error notification
              if (canShow) {
                if (progressNotification) {
                  this.notificationsService.removeNotification(progressNotification.id);
                }

                this.notificationsService.addNotification(
                  NotificationType.Error,
                  'Not found',
                  error?.description,
                );
              }
              break;
            }

            default: {
              // Show current error notification
              if (canShow) {
                if (progressNotification) {
                  this.notificationsService.removeNotification(progressNotification.id);
                }

                this.notificationsService.addNotification(
                  NotificationType.Error,
                  'Something went wrong',
                  error?.description,
                );
              }
              break;
            }
          }
        } else {
          // Show current error notification
          if (canShow) {
            if (progressNotification) {
              this.notificationsService.removeNotification(progressNotification.id);
            }

            this.notificationsService.addNotification(
              NotificationType.Error,
              'Something went wrong',
              error?.description,
            );
          }
        }

        return throwError(() => e);
      }),
    );
  }

  public canShowNotification(request: HttpRequest<unknown>): NotificationCondition {
    // filter by method and url
    if (request.method === 'POST' || request.method === 'PUT') {
      for (const [url, condition] of Object.entries(ON_POST_PUT_NOTIFICATION_URLS_CONDITIONS)) {
        if (request.url.includes(url)) {
          return condition;
        }
      }

      return { canShow: true, canShowProgress: true };
    } else {
      return { canShow: false, canShowProgress: false };
    }
  }
}
