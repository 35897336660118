import { MatButtonToggleDefaultOptions } from '@angular/material/button-toggle';
import { RippleGlobalOptions } from '@angular/material/core';
import { MatSlideToggleDefaultOptions } from '@angular/material/slide-toggle';

export const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 100,
  },
};

export const matButtonToggleDefaultOptions: MatButtonToggleDefaultOptions = {
  hideSingleSelectionIndicator: true,
};

export const matSlideToggleDefaultOptions: MatSlideToggleDefaultOptions = {
  hideIcon: true,
  disabledInteractive: true,
};
